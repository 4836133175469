.navbar{
    position: fixed;
    height: 30px;
    top: 0;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #111;
}

.logo{
    position: fixed;
    left: 20px;
    width: 80px;
}

.avatar{
    position: fixed;
    right: 20px;
    width: 30px;
}