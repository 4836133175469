.banner{
    /* background-image: url(https://wallpaperaccess.com/full/2703652.png); */
    background-size: cover;
    height: 600px;
    color: white;
    
}

.content{
    padding-top: 140px;
    height: 190px;
    margin: 9px;
}

.title{
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
}

.button{
    color: white;
    border-radius: 5px;
    outline: none;
    font-weight: 700;
    border: none;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: rgba(51, 51, 51, 0.5);
    margin-right: 1rem ;
}

.description{
    width: 45rem;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: 1rem;
    height: 80px;
    max-width: 360px;
}

.fade{
    height: 7.4rem;
    background-image: linear-gradient(180deg,transparent,rgba(37,37,37,.61),#111);
    padding-top: 152px;
}