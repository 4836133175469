.row{
    margin-left: 20px;
    color: white;
}

.post{
    display: flex;
    padding: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.post::-webkit-scrollbar{
    display: none;
}

.poster{
    max-height: 250px;
    margin-right: 10px;

}

.smallPoster{
    max-height: 150px;
    margin-right: 10px;
}